<template>
  <component :is="userDetails === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="userDetails === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body"></div>
    </b-alert>

    <settings-information
      :userData="userDetails"
      v-if="userDetails.name"
    ></settings-information>
    <organizations-list
      v-if="$store.state.authStore.currentUser.role == 'SUPERADMIN'"
      class="mt-2"
    >
    </organizations-list>
    <users-list
      v-if="
        $store.state.authStore.currentUser.role == 'ADMIN' ||
        $store.state.authStore.currentUser.role == 'SUPERADMIN'
      "
      class="mt-2"
    >
    </users-list>

  </component>
</template>

<script>
import { BCard, BAlert, BLink } from "bootstrap-vue";
import SettingsInformation from "./components/settingsinformation.vue";
import settingsStoreModule from "./storeModule/settingsModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import UsersList from "./components/userslist/userslist.vue";
import OrganizationsList from "./components/organization/organizations-list/OrganizationsList.vue"
export default {
  components: {
    BCard,
    BAlert,
    BLink,
    SettingsInformation,
    UsersList,
    OrganizationsList
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "userSettings"; //app-generation-history

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, settingsStoreModule);

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    return {};
  },
  computed: {
    userDetails() {
      const user = this.$store.state.authStore.currentUser;
      return {
        avatar: user.avatar ? user.avatar : "",
        name: user.name,
        id: user.id,
        role: user.role,
        email: user.email,
        status: user.status,
        organization: user.organization,
        // organization: user.organization.name ||"",
        // userDataInfo: {
        //   dob: user.dob || "",
        //   mobile: user.mobile || "",
        //   website: user.website || "",
        //   language: user.language || "English",
        //   gender: user.gender || "Male",
        //   contactOptions: user.contactOptions || ["Email"],
        //   addressLine1: user.addressLine1 || "",
        //   addressLine2: user.addressLine2 || "",
        //   postcode: user.postcode || "",
        //   city: user.city || "",
        //   state: user.state || "",
        //   country: user.country || "",
        // },
      };
    },
  },
};
</script>

<style></style>
