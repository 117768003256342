<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          :ref="refInputEl"
          :src.sync="previewEl"
          :text="avatarText(userData.name)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            id="contilt-profile-photo"
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">
            <b-spinner variant="primary" small v-if="loading" />
            <span v-else> Upload image </span>
          </span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="removeAvatar"
          :disabled="loading"
        >
          <span class="d-none d-sm-inline">Remove avatar</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-row>
        <!-- Field: Full Name -->

        <b-col cols="12" md="4">
          <b-form-group label="UserName" label-for="UserName">
            <b-form-input v-model="userData.name" :disabled="true" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="user-status">
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[{ label: userData.status, value: userData.status }]"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group label="User Role" label-for="user-role">
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[{ label: userData.role, value: userData.role }]"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-role"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Organization -->
        <b-col
          cols="12"
          md="4"
          v-if="userData.role == 'SUPERADMIN' || userData.role == 'ADMIN'"
        >
          <b-form-group label="Organization" label-for="organization">
            <b-form-input
              v-model="userData.organization.name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- <hr /> -->
    <!-- <editor-details :userDataInfo="userData.userDataInfo"></editor-details> -->

    <!-- Action Buttons -->
    <div class="d-flex align-items-center justify-content-center">
      <b-button
        variant="primary"
        type="submit"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="onSubmit"
        :disabled="loading"
      >
        Save Changes
      </b-button>
    </div>
    <hr />

    <!-- <b-button variant="outline-secondary" type="reset"> Reset </b-button> -->
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup,
  BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useInputImageRenderer } from "../../../../../contilt/shared-logic/compressImage";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadioGroup,
    BSpinner,

    vSelect,
    // EditorDetails,
  },
  props: ["userData"],
  setup(props) {
    const resolveUserRoleVariant = (role) => {
      if (role === "USER") return "primary";
      if (role === "author") return "warning";
      if (role === "maintainer") return "success";
      if (role === "ADMIN") return "success";
      if (role === "editor") return "info";
      return "primary";
    };
    const avatarChanged = ref(false);
    const refInputEl = ref(null);
    let previewEl = ref(null);
    let loading = ref(false);
    function showLoading(val) {
      loading.value = val;
    }

    if (props.userData.avatar) {
      refInputEl.value = props.userData.avatar;
      previewEl.value = props.userData.avatar;
    }

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      showLoading,
      null,
      (base64) => {
        props.userData.avatar = base64;
        previewEl.value = base64;
        avatarChanged.value = true;
      }
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer,
      avatarChanged,
      loading,
    };
  },
  methods: {
    onSubmit() {
      const dataToSend = {
        userData: { name: this.userData.name },
        id: this.userData.id,
      };
      if (this.avatarChanged) {
        this.userData.image = this.userData.avatar;
        dataToSend.userData.avatar = this.userData.avatar;
        this.avatarChanged = false;
      }

      this.$store
        .dispatch("userSettings/editUser", dataToSend)
        .then((res) => {
          if (res) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Data Updated",
                icon: "CoffeeIcon",
                variant: "success",
                text: `you have successfully updated ${this.userData.name} data `,
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error happened",
              icon: "AlertTriangleIcon",
              variant: "light-danger",
              text: `Something went wrong`,
            },
          });
        });
    },
    removeAvatar() {
      this.userData.avatar = "";
      this.avatarChanged = true;
      this.previewEl = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
