export const useInputImageRenderer = (inputEl, showLoading, type, callback) => {
  const inputImageRenderer = () => {
    showLoading && showLoading(true);
    const file = inputEl.value.files[0];

    const MAX_WIDTH = 320;
    const MAX_HEIGHT = 180;
    const MIME_TYPE = type || "image/jpeg";
    const QUALITY = 0.7;

    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
      showLoading && showLoading(false);
      URL.revokeObjectURL(this.src);
      // Handle the failure properly
      showLoading && showLoading(false);
    };
    img.onload = function () {
      URL.revokeObjectURL(this.src);
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();

          reader.addEventListener(
            "load",
            () => {
              callback(reader.result);
            },
            false
          );

          if (blob) {
            reader.readAsDataURL(blob);
          }
          // Handle the compressed image. es. upload or save in local state
        },
        MIME_TYPE,
        QUALITY
      );
      showLoading && showLoading(false);
    };

    function calculateSize(img, maxWidth, maxHeight) {
      let width = img.width;
      let height = img.height;

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      return [width, height];
    }
  };
  return {
    inputImageRenderer,
  };
};
