<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New User</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group label="Full Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Organization"
            v-if="organizations && organizations.length > 0"
            rules="required"
          >
            <b-form-group
              label="Organization"
              label-for="organization"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.organization"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="organizations"
                :reduce="val => val.value"
                :clearable="false"
                input-id="organization"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Credits -->
          <validation-provider #default="validationContext" name="Credits">
            <b-form-group label="Credits" label-for="credits">
              <b-form-input
                id="credits"
                type="number"
                v-model.number="userData.quota"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="1000"
              />
              <b-form-text>For not setting credits, leave it blank</b-form-text>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Credits -->
          <validation-provider v-if="isEditMode" #default="validationContext" name="Current credits">
            <b-form-group label="Current credits" label-for="currentcredits">
              <b-form-input
                id="currentcredits"
                type="number"
                v-model.number="userData.currentQuota"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="1000"
              />
              <b-form-text>For not setting credits, leave it blank</b-form-text>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isDisabled"
            >
              {{ isEditMode ? "Update" : "Add" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormText,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormText,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    editorData: {
      type: Object,
      required: false,
    },
    organizations: {
      type: Array,
      required: false,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
 
      userData: {
        name: "",
        role: null,
        email: "",
      },
    };
  },
  setup(props) {
    const blankUserData = {
      name: "",
      role: null,
      organization: store.state.authStore.currentUser.organization.id
    };
    let id = props.editorData ? props.editorData.id : null;
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      id,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    if (this.editorData) {
      this.userData.name = this.editorData.name;
      this.userData.email = this.editorData.email;
      this.userData.role = this.editorData.role;
      this.userData.quota = this.editorData.quota;
      this.userData.currentQuota = this.editorData.currentQuota;
      this.userData.organization = this.editorData.organization;
    }
    else {
      this.userData.organization = this.$store.state.authStore.currentUser.organization.id
    }
    
  },
  methods: {
    onSubmit() {
      if (this.isEditMode) {
        this.$store
          .dispatch("userSettings/editUser", {
            id: this.id,
            userData: this.userData
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "User Updated",
                icon: "CoffeeIcon",
                variant: "success",
                text: `you have successfully updated ${this.userData.name} details `,
              },
            });
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-user-sidebar-active", false);
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error happened",
                icon: "AlertTriangleIcon",
                variant: "light-danger",
                text: `Something went wrong`,
              },
            });
          });
      } else {
        
        this.$store
          .dispatch("userSettings/addUser", this.userData,)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Account Created",
                icon: "CoffeeIcon",
                variant: "success",
                text: `you have successfully created ${this.userData.name} account `,
              },
            });
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-user-sidebar-active", false);
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error happened",
                icon: "AlertTriangleIcon",
                variant: "light-danger",
                text: `Something went wrong`,
              },
            });
          });
      }
    },
  },
  computed: {
    isDisabled() {
      const disabled =
        this.userData.email && this.userData.name && this.userData.role;
      return !disabled;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
