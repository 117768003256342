<template>
  <div>
    <b-modal
      id="modal-danger"
      ok-only
      ok-variant="danger"
      ok-title="Confirm"
      modal-class="modal-danger"
      centered
      title="Confirm your action"
      @ok="deleteUser"
    >
      <b-card-text>
        Are you sure you want to delete {{ username }} 's account !
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
export default {
  components: { BModal, BCardText },
  props: ["deleteUser", "username"],
};
</script>

<style lang="scss" scoped></style>
