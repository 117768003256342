import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
// import { title } from "@core/utils/filter";
import { settingsUsersTableColumns } from "../../../../../../contilt/configs/tables/constants/tablesdata";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getBasicTableVars } from "../../../../../../contilt/configs/tables/logic/tablesBasicLogic";

export default function useUsersList(data) {
  // Use toast
  const toast = useToast();

  // Table Handlers
  const tableColumns = settingsUsersTableColumns;
  const searchQuery = ref(data.search || "");
  const roleFilter = ref(null);
  const statusFilter = ref(null);

  const {
    refUserListTable,
    perPage,
    totalUsers,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refetchData,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
  } = getBasicTableVars();

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? store.state.userSettings.usersList.length
      : 0;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    let dataToSend = {
      limit: 10,
      page: currentPage.value,
      name: searchQuery.value,
    };
    store
      .dispatch("userSettings/fetchUsers", dataToSend)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalUsers.value = totalResults;
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    // perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    statusFilter,
  };
}
