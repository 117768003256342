import axios from "@axios";

export default {
  namespaced: true,
  state: {
    usersList: [],
  },
  getters: {},
  mutations: {
    setUsers(state, val) {
      state.usersList = val;
    },
  },
  actions: {
    editUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/users/${id}`, userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/users", { params: data })
          .then((response) => {
            ctx.commit("setUsers", response.data.results);
            resolve(response);
          })
          .catch((e) => reject(e));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/users", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getRegistrationToken(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/auth/generate-register-token/`, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getResetPasswordToken(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/generate-reset-password-token`, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
