<template>
  <div>
    <h4>Editors List</h4>
    <user-list-add-new
      :organizations="organizations"
      v-if="isAddNewUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
      :editorData="selectedEditor"
      :isEditMode="isEditMode"
    />

    <confirm-modal
      v-if="userIdToDelete"
      :deleteUser="deleteUser"
      :username="userIdToDelete.name"
    ></confirm-modal>

    <b-card no-body class="mb-0">
      <div class="m-0 mb-1 mt-1">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col lg="10" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="debounceSearch"
              />
            </div>
          </b-col>
          <b-col lg="2">
            <b-button variant="primary" @click="() => createEditor()">
              <span class="text-nowrap">Add User</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.name }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: quota -->
        <template #cell(quota)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.quota
            }}</span>
          </div>
        </template>

        <!-- Column: quota -->
        <template #cell(credits)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.currentQuota
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="activate(data.item)"
              v-if="data.item.status == 'PENDING'"
            >
              <feather-icon icon="ExternalLinkIcon" />
              <span class="align-middle ml-50">Activate</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="resetPassword(data.item)"
            >
              <feather-icon icon="HashIcon" />
              <span class="align-middle ml-50">Reset Password</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editEditor(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.modal-danger>
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
                @click="userIdToDelete = data.item"
                >Delete</span
              >
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BAvatar,
  BLink,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import axios from "@axios"
import vSelect from "vue-select";
import useUsersList from "./userslistLogic";
import { ref } from "@vue/composition-api";
import UserListAddNew from "../addnewuser/addnewuser.vue";
import router from "@/router";
import ConfirmModal from "../confirmModal/confirmmodal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAvatar,
    avatarText,
    BLink,

    vSelect,
    UserListAddNew,
    ConfirmModal,
  },
  data() {
    return {
      organizations: [],
      debounce: "",
      searchTerm: this.searchQuery || "",
      selectedEditor: {},
      userIdToDelete: null,
      isEditMode: false,
    };
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false);

    const statusOptions = [
      { label: "Pending", value: "PENDING" },
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
    ];

    const roleOptions = [
      { label: "Admin", value: "ADMIN" },
      { label: "User", value: "USER" },
      { label: "Developer", value: "DEVELOPER" }
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      // perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = useUsersList(router.history.current.query);

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,
    };
  },
  computed: {
    isSuperAdmin() {
      return this.$store.state.authStore.currentUser.role === "SUPERADMIN";
    },
  },
  created(){
    if(this.isSuperAdmin){
      axios.get("/organizations", {params: {limit: 1000, selectedFields: "name id"}}).then(response => {
        const organizations = response.data.results.map((x) => {
          return {
            value: x.id,
            label: x.name,
          };
        });
        this.organizations = organizations;
      })
    }
  },
  methods: {
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchQuery = this.searchTerm;
        // event.preventDefault();
        this.$router.push({
          // path: "/apps/users/edit/21",
          query: {
            ...this.$router.history.current.query,
            ["search"]: this.searchQuery,
          },
        });
      }, 400);
    },
    createEditor() {
      this.selectedEditor = null;
      this.isAddNewUserSidebarActive = true;
      this.isEditMode = false;
    },
    editEditor(data) {
      this.selectedEditor = data;
      this.isAddNewUserSidebarActive = true;
      this.isEditMode = true;
    },
    deleteUser() {
      this.$store
        .dispatch("userSettings/deleteUser", { id: this.userIdToDelete.id })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "User Deleted",
              icon: "CoffeeIcon",
              variant: "success",
              text: `you have successfully deleted ${this.userIdToDelete.name} account `,
            },
          });
          this.isAddNewUserSidebarActive = false;
          this.userIdToDelete = null;
          this.refetchData();
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error happened",
              icon: "AlertTriangleIcon",
              variant: "light-danger",
              text: `Something went wrong`,
            },
          });
          console.log(e);
        });
    },
    activate(data) {
      this.$store
        .dispatch("userSettings/getRegistrationToken", data)
        .then((response) => {
          this.$router.push({
            path: `/registerEditor/${response.data.registerToken}`,
            query: {
              token: response.data.registerToken,
              email: data.email,
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error happened",
              icon: "AlertTriangleIcon",
              variant: "light-danger",
              text: `Something went wrong`,
            },
          });
          console.log(e);
        });
    },
    resetPassword(data) {
      this.$store
        .dispatch("userSettings/getResetPasswordToken", data)
        .then((response) => {
          this.$router.push({
            name: "auth-reset-password",
            params: {
              token: response.data.resetPasswordToken
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error happened",
              icon: "AlertTriangleIcon",
              variant: "light-danger",
              text: `Something went wrong`,
            },
          });
          console.log(e);
        });
    },
  },
   
  
  // beforeMount() {
  //   window.addEventListener("beforeunload", (event) => {
  //     if (!this.isEditing) return;
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set.
  //     event.returnValue = "";
  //   });
  // },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
